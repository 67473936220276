@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

button {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  background: no-repeat;
  font-family: inherit;
  font-weight: inherit;
  outline: none;
}

.labelMeasurement {
  border: 1px solid #7c7c7c;
  border-radius: 1rem;
  font-size: 1em;
  height: 2rem;
  width: auto;
  padding: 0 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #676767;
  align-items: center;
  flex-direction: column;
  background-color: #DEDEDE;
}

.labelTopMeasurement {
  border: 1px solid #7c7c7c;
  border-radius: 1rem;
  font-size: 1em;
  height: 2rem;
  width: auto;
  padding: 0 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #676767;
  align-items: center;
  flex-direction: column;
  background-color: #DEDEDE;
}